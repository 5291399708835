import {
  API_BASE_URL,
  BOOK_LIST_SIZE,
  POLL_LIST_SIZE,
  ACCESS_TOKEN,
} from "../constants";

const request = (options) => {
  const headers = new Headers({
    "Content-Type": "application/json",
  });

  if (localStorage.getItem(ACCESS_TOKEN)) {
    headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem(ACCESS_TOKEN)
    );
  }

  const defaults = { headers: headers };
  options = Object.assign({}, defaults, options);

  return fetch(options.url, options).then((response) =>
    response.json().then((json) => {
      if (!response.ok) {
        return Promise.reject(json);
      }
      return json;
    })
  );
};

export function getAllBooks(page, size) {
  page = page || 0;
  size = size || BOOK_LIST_SIZE;

  return request({
    url: API_BASE_URL + "/api/books?page=" + page + "&size=" + size,
    method: "GET",
  });
}

export function getAllPolls(page, size) {
  page = page || 0;
  size = size || POLL_LIST_SIZE;

  return request({
    url: API_BASE_URL + "/api/polls?page=" + page + "&size=" + size,
    method: "GET",
  });
}

export function createPoll(pollData) {
  console.log("STRINGIFY", JSON.stringify(pollData));
  return request({
    url: API_BASE_URL + "/api/polls",
    method: "POST",
    body: JSON.stringify(pollData),
  });
}

export function castVote(voteData) {
  return request({
    url: API_BASE_URL + "/api/polls/" + voteData.pollId + "/votes",
    method: "POST",
    body: JSON.stringify(voteData),
  });
}

export function login(loginRequest) {
  return request({
    url: API_BASE_URL + "/api/auth/signin",
    method: "POST",
    body: JSON.stringify(loginRequest),
  });
}

export function signup(signupRequest) {
  return request({
    url: API_BASE_URL + "/api/auth/signup",
    method: "POST",
    body: JSON.stringify(signupRequest),
  });
}

export function checkUsernameAvailability(username) {
  return request({
    url: API_BASE_URL + "/api/user/checkUsernameAvailability?username=" + username,
    method: "GET",
  });
}

export function getCurrentUser() {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: API_BASE_URL + "/api/user/me",
    method: "GET",
  });
}

export function getUserProfile(username) {
  console.log("getUserProfile");

  return request({
    url: API_BASE_URL + "/api/users/" + username,
    method: "GET",
  });
}

export function getUserCreatedPolls(userid, page, size) {
  page = page || 0;
  size = size || POLL_LIST_SIZE;
  console.log("getUserCreatedPolls");

  return request({
    url:
      API_BASE_URL +
      "/users/" +
      userid +
      "/polls?page=" +
      page +
      "&size=" +
      size,
    method: "GET",
  });
}

export function getUserCreatedBooks(userid, page, size) {
  page = page || 0;
  size = size || BOOK_LIST_SIZE;
  console.log("getUserCreatedBooks = ", API_BASE_URL + "/api/users/" + userid + "/books?page=" + page + "&size=" + size);
  return request({
    url:
      API_BASE_URL +
      "/api/users/" +
      userid +
      "/books?page=" +
      page +
      "&size=" +
      size,
    method: "GET",
  });
}

export function getUserVotedPolls(username, page, size) {
  page = page || 0;
  size = size || POLL_LIST_SIZE;
  console.log("getUserVotedPolls");

  return request({
    url:
      API_BASE_URL +
      "/api/users/" +
      username +
      "/votes?page=" +
      page +
      "&size=" +
      size,
    method: "GET",
  });
}

export function downloadBook(isbn, subscriber) {
  return request({
    url: API_BASE_URL + "/api/downloads?isbn=" + isbn + "&subscriber=" + subscriber,
    method: "GET",
  });
}

export function downloadCount(id) {
  return request({
    url: API_BASE_URL + "/api/downloads/count/" + id,
    method: "GET",
  });
}

export function downloadUser() {
  return request({
    url: API_BASE_URL + "/api/downloads/view",
    method: "GET",
  });
}

export function getAllUser() {
  return request({
    url: API_BASE_URL + "/api/users/all",
    method: "GET",
  });
}

export function getAllBook() {
  return request({
    url: API_BASE_URL + "/api/books/all",
    method: "GET",
  });
}

export function getBookByUserId(userid) {
  return request({
    url: API_BASE_URL + "/api/users/" + userid + "/books",
    method: "GET",
  });
}

export async function getDateDownloads(id, startDate, endDate) {
  let res = await fetch(
    API_BASE_URL + `/api/downloads/timeCount/${id}?from=${startDate}&to=${endDate}`
  );
  let data = await res.json();
  return data;

  // return request({
  //     url: API_BASE_URL + "/api/downloads/timeCount/" + id + "?from=" + startDate + "&to=" + endDate,
  //     method: 'GET'
  // });
}

export async function getDateAcademyDownloads(id, startDate, endDate) {
  let res = await fetch(
    API_BASE_URL +
      `/api/downloads/subCount/${id}/academy?from=${startDate}&to=${endDate}`
  );
  let data = await res.json();
  return data;

  // return request({
  //     url: API_BASE_URL + "/api/downloads/subCount/" + id + "/academy" + "?from=" + startDate + "&to=" + endDate,
  //     method: 'GET'
  // });
}

export async function getDateB2cDownloads(id, startDate, endDate) {
  let res = await fetch(
    API_BASE_URL +
      `/api/downloads/subCount/${id}/b2c?from=${startDate}&to=${endDate}`
  );
  let data = await res.json();
  return data;

  // return request({
  //     url: API_BASE_URL + "/api/downloads/subCount/" + id + "/b2c" + "?from=" + startDate + "&to=" + endDate,
  //     method: 'GET'
  // });
}
export async function getTimeAllBook(startDate, endDate) {
  let res = await fetch(
    API_BASE_URL + `/api/books/all/time?from=${startDate}&to=${endDate}`
  );
  let data = await res.json();
  return data;
}

export async function getTimeAllb2cCount(startDate, endDate) {
  let res = await fetch(
    API_BASE_URL + `/api/downloads/subCount/b2c?from=${startDate}&to=${endDate}`
  );
  let data = await res.json();
  return data;
}

export async function getTimeAllB2BCount(startDate, endDate) {
  let res = await fetch(
    API_BASE_URL + `/api/downloads/subCount/academy?from=${startDate}&to=${endDate}`
  );
  let data = await res.json();
  return data;
}

export async function getTimeAllCount(type, startDate, endDate) {
  let res = await fetch(
      API_BASE_URL + `/api/downloads/subCount/${type}?from=${startDate}&to=${endDate}`
  );
  let data = await res.json();
  return data;
}
