export function formatDate(dateString) {
  const date = new Date(dateString);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  return monthNames[monthIndex] + " " + year;
}

export function formatDateTime(dateTimeString, type) {
  const date = new Date(dateTimeString);

  const monthNames = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];

  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  const dateFirstTimeFunc = () => {
      return (
        year +
        "-" +
        monthNames[monthIndex] +
        "-" +
        ("0" + date.getDate()).slice(-2) +
        "T00:00:00.000Z"
      );
  };

  const dateLastTimeFunc = () => {
      return (
        year +
        "-" +
        monthNames[monthIndex] +
        "-" +
        ("0" + date.getDate()).slice(-2) +
        "T23:59:59.000Z"
      );
    
  };

  if (type === 1) {
    return dateFirstTimeFunc();
  } else if (type === 2) {
    return dateLastTimeFunc();
  } else {
    return (
      year +
      " " +
      monthNames[monthIndex] +
      " " +
      ("0" + date.getDate()).slice(-2)
    );
  }
}
