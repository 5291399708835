export const API_BASE_URL = "https://pagebuilder.kr:9000";
// export const API_BASE_URL = "http://127.0.0.1:9000";
export const ACCESS_TOKEN = "accessToken";

export const BOOK_LIST_SIZE = 50;
export const PUBLISHER_LIST_SIZE = 100;
export const POLL_LIST_SIZE = 30;

export const MAX_CHOICES = 6;
export const POLL_QUESTION_MAX_LENGTH = 140;
export const POLL_CHOICE_MAX_LENGTH = 40;

export const NAME_MIN_LENGTH = 2;
export const NAME_MAX_LENGTH = 40;

export const USERNAME_MIN_LENGTH = 2;
export const USERNAME_MAX_LENGTH = 15;

export const PASSWORD_MIN_LENGTH = 6;
export const PASSWORD_MAX_LENGTH = 20;
