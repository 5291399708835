import React, { Component, useState,useRef,useEffect } from 'react';
import {downloadUser, getAllUser} from '../util/APIUtils';
import { MAX_CHOICES, POLL_QUESTION_MAX_LENGTH, POLL_CHOICE_MAX_LENGTH } from '../constants';
import './NewPoll.css';  
import { Form, Input, Select, Table, Tag, Space} from 'antd';
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import "react-datepicker/dist/react-datepicker.css";
import {formatDateTime} from "../util/Helpers";

const { Column } = Table;
const Option = Select.Option;
const FormItem = Form.Item;
const { TextArea } = Input

function NewPoll(props) {

    const {isAuthenticated, currentUser} = props;


    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(new Date());

    const [pubUser, setPubUser] = useState({
        name: [],
    })

    const [downCounts, setDownCounts] = useState({
        download: [],
    })

    let listOfBook;
    const getPubUser = () => {
        listOfBook = getAllUser();

        setPubUser({
            ...pubUser
        });

        listOfBook.then(res => {

                setPubUser({
                    name: res.content,
                    toDown: 0,
                });
        }).catch(error => {
            setPubUser({
                ...pubUser
            })
        });
    }

    const [downList, setDownList] = useState({
        downBooks: [],
    })

    let downUser;
    const getDownUser = () => {
        downUser = downloadUser();

        setDownList({
            ...downList
        });

        downUser.then(res => {
            setDownList({
                downBooks: res.content
            });

        }).catch(error => {
            setDownList({
                ...downList
            })
        });
    }
    let count = [];
    let totalCount;

    const down = () => {
        if(currentUser.id === 1) {
            for(let i= 0; i<pubUser.name.length-2; i++){
                count[i] = 0;
                totalCount = 0;
            }
        }else{
            count[0] = 0;
            totalCount = 0;
        }

        setDownCounts({
            download: [],
        })

        pubUser.name.map((data, idx) => {
            downList.downBooks.map((d, i) => {
                if(formatDateTime(startDate) <= d.createdAt && d.createdAt <= formatDateTime(endDate)){
                    if(currentUser.id === 1){
                        if (pubUser.name[idx].id === downList.downBooks[i].pubId && pubUser.name[idx].id != 1 && pubUser.name[idx].id != 2) {
                            count[idx] += 1;
                            totalCount += 1;
                        }
                    }else{

                        if (pubUser.name[idx].id === downList.downBooks[i].pubId) {
                            if(downList.downBooks[i].pubId === currentUser.id){
                                count[0] += 1;
                                totalCount += 1;
                            }
                        }
                    }
                }
            })
        })
        setDownCounts({
            download: count,
        })

        setPubUser({
            ...pubUser,
            toDown: totalCount
        })
    }

    useEffect(() => {
        getPubUser();
        getDownUser();
        down();

        return () => {
            setPubUser({
                pubUser: [],
            })

            setDownList({
                downList: [],
            })

            setDownCounts({
                downCounts: [],
            })

            if(currentUser.id === 1) {
                for(let i= 0; i<pubUser.name.length; i++){
                    count[i] = 0;
                }
            }else{
                count[0] = 0;
            }

        }

    }, [isAuthenticated])


    useEffect(() => {
        down()

        if(formatDateTime(startDate) > formatDateTime(endDate)){
            alert("시작 날짜가 더 클 수 없음")
            setStartDate(endDate);
        }

    }, [startDate, endDate])

    useEffect(() => {
        downUser.then(res => {
            res.content.map((val, idx) => {
                val.createdAt = formatDateTime(val.createdAt)
            })
            setDownList({
                downBooks: res.content,
            })
        })
    }, [])
    //console.log(downList.downBooks)

        return (
            <div>
                <div className="new-poll-container">
                    <DatePicker
                        placeholderText="선택해주세요"
                        selected={startDate}
                        onChange={date => setStartDate(date)}
                        dateFormat="yyyy-MM"
                        showMonthYearPicker
                    />
                    ~
                    <DatePicker
                        selected={endDate}
                        onChange={date => setEndDate(date)}
                        dateFormat="yyyy-MM"
                        showMonthYearPicker
                    />
                </div>
                <div className="tetete">
                    <div className="t">
                        {
                            pubUser.name.map((data, idx) => <> <div key={idx} > {currentUser.id === 1 ? data.id === 1 || data.id === 2 ? null : data.name : currentUser.id === data.id ? data.name : null } </div> </>)
                        }
                    </div>
                    <div className="t">
                        {
                            downCounts.download.map((data, idx) => <> <div key={idx} > {data} </div> </>)
                        }
                    </div>
                    <div>
                        총 다운 수 {
                            pubUser.toDown
                    }
                    </div>
                </div>
            </div>
        );
}


export default NewPoll;