import React, { useState, useEffect } from "react";
import {
  getAllBooks,
  getUserCreatedBooks,
  getAllBook,
  getUserProfile,
  downloadCount,
  downloadUser,
  getAllUser,
  getDateDownloads,
  getDateAcademyDownloads,
  getDateB2cDownloads,
  getTimeAllBook,
  getTimeAllCount,
} from "../util/APIUtils";
import Book from "./Book";

import LoadingIndicator from "../common/LoadingIndicator";

import { Button, Select, Table, Tag, Space } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import { BOOK_LIST_SIZE } from "../constants";
import { withRouter } from "react-router-dom";
import "./BookList.css";
import { formatDateTime } from "../util/Helpers";
import DatePicker from "react-datepicker";

const { Column, ColumnGroup } = Table;

function BookList(props) {
  const [name, setName] = useState("");
  const [search, setSearch] = useState("");
  // const [upBook, setUpBook] = useState("");
  const [academyC, setAcademyC] = useState(0);
  const [b2cC, setB2cC] = useState(0);

  const [startDate, setStartDate] = useState(
    new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() - (new Date().getDate() - 1)
    )
  );
  const [endDate, setEndDate] = useState(new Date());
  const onChange = (e) => {
    setName(e.target.value);
  };
  const onClick = (e) => {
    // console.log(name);
    const userDate = getUserProfile(name);
    userDate.then((response) => {
      setUserState({
        id: response.id,
        name: response.name,
      });
    });
    promise = getUserCreatedBooks(userState.id, 0, 20);
    loadBooks();
  };
  const [userState, setUserState] = useState({
    id: 1,
    name: "",
  });

  const [selectName, setSelectName] = useState("전체");

  const { isAuthenticated, currentUser } = props;
  let totalDownCount = 0;
  let academyTotalDownCount = 0;
  let b2cTotalDownCount = 0;
  let schoolTotalDownCount = 0;
  const [bookState, setBookState] = useState({
    books: [],
    page: 0,
    size: 10,
    totalElements: 0,
    totalPages: 0,
    last: true,
    isLoading: false,
  });

  const [userName, setUserName] = useState({
    name: [],
  });

  const [downList, setDownList] = useState({
    downBooks: [],
  });

  let downUser;
  const getDownUser = () => {
    downUser = downloadUser();
    setDownList({
      ...downList,
    });

    downUser
      .then((res) => {
        setDownList({
          downBooks: res.content,
        });
      })
      .catch((error) => {
        setDownList({
          ...downList,
        });
      });
  };
  let listOfBook;
  const gettingList = () => {
    listOfBook = getAllUser();
    setUserName({
      ...userName,
    });
    listOfBook
      .then((res) => {
        const sortedName = res.content.map((data) => data.name);
        const sorted = sortedName.sort();

        setUserName({
          name: res.content,
          sortedName: sorted,
        });
      })
      .catch((error) => {
        setUserName({
          ...userName,
        });
      });
  };

  /*
  let updateBook;
  const getAllBooks = () => {
    updateBook = getTimeAllBook(
      formatDateTime(startDate, 1),
      formatDateTime(endDate, 2)
    );
    setUpBook({
      ...upBook,
    });
    updateBook
      .then((res) => {
        totalDownCount = 0;
        academyTotalDownCount = 0;
        b2cTotalDownCount = 0;
        schoolTotalDownCount = 0;
        res.content.map((val, idx) => {
          val.createdAt = formatDateTime(val.createdAt);
          val.downloadCount -= val.schoolCount;
        });
        // console.log(res);

        setUpBook({
          books: res.content,
          page: res.page,
          size: res.size,
          totalElements: res.totalElements,
          totalPages: res.totalPages,
          last: res.last,
          isLoading: false,
        });
      })
      .catch((error) => {
        setUpBook({
          ...upBook,
        });
      });
  };

   */
  let promise;
  const loadBooks = (page = 0, size = BOOK_LIST_SIZE) => {
    let publisher = selectName;
    if (currentUser) {
      if (currentUser.id == 1 || currentUser.id == 2) {
        // promise = getAllBook();
        promise = getTimeAllBook(
          formatDateTime(startDate, 1),
          formatDateTime(endDate, 2)
        );
      } else {
        setSelectName(publisher = currentUser.name);
        // console.log(currentUser.id);
        // promise = getUserCreatedBooks(currentUser.id, page, size);
        promise = getTimeAllBook(
            formatDateTime(startDate, 1),
            formatDateTime(endDate, 2)
        );
      }
    } else {
      promise = getAllBook();
    }

    if (!promise) {
      return;
    }

    setBookState({
      ...bookState,
      isLoading: true,
    });

    promise
      .then((response) => {
        let bookCount = 0;
        totalDownCount = 0;
        academyTotalDownCount = 0;
        b2cTotalDownCount = 0;
        schoolTotalDownCount = 0;
        response.content.map((val, idx) => {
          val.createdAt = formatDateTime(val.createdAt);
          val.downloadCount -= val.schoolCount;
          if (publisher == "전체" || val.publisher == publisher) {
            totalDownCount += val.downloadCount;
            academyTotalDownCount += val.academyCount;
            b2cTotalDownCount += val.b2cCount;
            schoolTotalDownCount += val.schoolCount;
            bookCount += 1;
          }
        });

        setBookState({
          books: response.content,
          page: response.page,
          size: response.size,
          totalElements: bookCount,
          totalPages: response.totalPages,
          last: response.last,
          isLoading: false,
          downloadCount: totalDownCount,
          totalAcademyCount: academyTotalDownCount,
          totalB2cCount: b2cTotalDownCount,
        });
      })
      .catch((error) => {
        setBookState({
          ...bookState,
          isLoading: false,
        });
      });
  };
  const totalCountFunc = () => {
    let cou = document.getElementsByClassName("notranslate");

    setBookState({
      ...bookState,
      isLoading: true,
    });

    loadBooks()

    // getAllBooks();
//
//     getTimeAllCount(
// "academy",
//       formatDateTime(startDate, 1),
//       formatDateTime(endDate, 2)
//     ).then((res) => {
//       setAcademyC(res);
//     });
//     // b2b로 통일되어 사용
//     getTimeAllCount(
// "b2b",
//       formatDateTime(startDate, 1),
//       formatDateTime(endDate, 2)
//     ).then((res) => {
//       setB2cC(res);
//     });
//
//     setBookState({
//       ...bookState,
//       isLoading: false,
//       downloadCount: academyC + b2cC,
//       totalAcademyCount: academyC,
//       totalB2cCount: b2cC,
//     });
    document.getElementsByClassName("notranslate")[
      cou.length - 1
    ].style.display = "flex";
    document.getElementsByClassName("loadingView")[0].style.display = "none";
  };

  useEffect(() => {
    loadBooks();
    gettingList();
    getDownUser();
    // getAllBooks();

    return () => {
      setBookState({
        books: [],
        page: 0,
        size: 10,
        totalElements: 0,
        totalPages: 0,
        last: true,
        currentVotes: [],
        isLoading: false,
        downloadCount: 0,
        totalAcademyCount: 0,
        totalB2cCount: 0,
        totalSchoolCount: 0,
      });
      setUserName({
        name: [],
      });
      setDownList({
        downBooks: [],
      });

      // setUpBook({
      //   books: [],
      //   page: 0,
      //   size: 10,
      //   totalElements: 0,
      //   totalPages: 0,
      //   last: true,
      //   currentVotes: [],
      //   isLoading: false,
      //   downloadCount: 0,
      //   totalAcademyCount: 0,
      //   totalB2cCount: 0,
      //   totalSchoolCount: 0,
      // });
    };
  }, [isAuthenticated]);

  // useEffect(() => {
  //   getTimeAllCount(
  //     "academy",
  //     formatDateTime(startDate, 1),
  //     formatDateTime(endDate, 2)
  //   ).then((res) => {
  //     setAcademyC(res);
  //   });
  //   // b2b로 통일해서 사용
  //   getTimeAllCount(
  //       "b2b",
  //     formatDateTime(startDate, 1),
  //     formatDateTime(endDate, 2)
  //   ).then((res) => {
  //     setB2cC(res);
  //   });
  //
  //   getAllBooks();
  // }, [startDate, endDate]);

  const ff = (names) => {
    let count = 0;
    let aCount = 0;
    let bCount = 0;
    let sCount = 0;
    let bookCount = 0;
    bookState.books.map((book, index) => {
      if (names == "all" || book.publisher == names) {
        count += book.downloadCount;
        aCount += book.academyCount;
        bCount += book.b2cCount;
        bookCount += 1;
      }
    });
    setBookState({
      ...bookState,
      downloadCount: count,
      totalAcademyCount: aCount,
      totalB2cCount: bCount,
      totalElements: bookCount,
    });
  };

  const scrollTop = () => {
    window.scroll(0, 0);
  };

  const columns = [
    {
      title: "도서제목",
      dataIndex: "title",
      key: "title",
      SortOrder: "descend",
      sorter: (a, b) => (a.title > b.title ? 1 : -1),
      defaultSortOrder: "ascend",
      filteredValue: [search],
      onFilter: (search, record) =>
        search == "" ? 1 : record.title.toLowerCase().includes(search),
      width: 550,
    },
    {
      title: "저자",
      dataIndex: "authors",
      width: 100,
      ellipsis: true,
    },
    {
      title: "출판사",
      dataIndex: "publisher",
      filteredValue: [selectName],
      onFilter: (selectName, record) =>
        selectName == "전체" ? 1 : record.publisher.indexOf(selectName) === 0,
      width: 150,
    },
    {
      title: "등록일",
      dataIndex: "createdAt",
      SortOrder: "descend",
      sorter: (a, b) => a.createdAt - b.createdAt,
      width: 100,
    },
    {
      title: "스트리밍수",
      dataIndex: "downloadCount",
      key: "downloadCount",
      SortOrder: "descend",
      sorter: (a, b) => a.downloadCount - b.downloadCount,
      width: 100,
    },
    // {
    //   title: "Academy",
    //   dataIndex: "academyCount",
    //   key: "academyCount",
    //   SortOrder: "descend",
    //   sorter: (a, b) => a.academyCount - b.academyCount,
    //   render: (v) => (v ? v : 0),
    //   width: 100,
    // },
    // {
    //   title: "B2C",
    //   dataIndex: "b2cCount",
    //   key: "b2cCount",
    //   SortOrder: "descend",
    //   sorter: (a, b) => a.b2cCount - b.b2cCount,
    //   render: (v) => (v ? v : 0),
    //   width: 100,
    // },
    // {
    //     title: 'School',
    //     dataIndex: 'schoolCount',
    //     key: 'schoolCount',
    //     SortOrder: 'descend',
    //     sorter: (a, b) => a.schoolCount - b.schoolCount,
    //     width:100
    // }
  ];

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  if (currentUser) {
    return (
      <div className="bookList_container">
        <div className="publisher_container">
          <div className="nameList_div">
            {currentUser.id === 1 || currentUser.id === 2 ? (
              <ul className="nameList">
                <Button
                  onClick={() => {
                    setSelectName("전체");
                    ff("all");
                  }}
                  className= {(selectName == "전체") ? "notranslate selected" : "notranslate"}
                >
                  전체
                </Button>
                {userName.name
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((data, idx) =>
                    data.id === 1 || data.id === 2 ? null : (
                      <Button
                        style={{
                          display: "block",
                          width: "150px",
                          marginTop: "5px",
                          textAlign: "left",
                        }}
                        className= {(selectName == data.name) ? "notranslate selected" : "notranslate"}
                        key={idx}
                        onClick={(e) => {
                          setSelectName(data.name);
                          ff(data.name);
                          scrollTop();
                        }}
                      >
                        {data.name}
                      </Button>
                    )
                  )}
              </ul>
            ) : (
                <ul className="nameList">
                </ul>
            )}
          </div>
        </div>
        <div className="polls-container">
          <div className="data_and_search">
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              dateFormat="yyyy-MM-dd"
            />
            ~
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              dateFormat="yyyy-MM-dd"
            />
            <input
                type={"text"}
                placeholder={"도서 제목 검색"}
                onChange={handleChange}
            ></input>
            <button
              onClick={() => {
                let cou = document.getElementsByClassName("notranslate");
                document.getElementsByClassName("notranslate")[
                  cou.length - 1
                ].style.display = "none";
                document.getElementsByClassName(
                  "loadingView"
                )[0].style.display = "flex";
                totalCountFunc();
                // setSelectName("전체");
              }}
            >
              {" "}
              조회{" "}
            </button>
          </div>
          <div className="total">
            <>
              출판사: {selectName}, 도서 수: {bookState.totalElements}, 스트리밍 수: {bookState.downloadCount}
              {/* academy
              스트리밍 수 {bookState.totalAcademyCount} / B2C 스트리밍 수{" "}
              {bookState.totalB2cCount}  */}
              {/*{" "}School 스트리밍 수 {bookState.totalSchoolCount} /*/}
            </>
          </div>

          <Table
            columns={columns}
            sortDirections={["ascend", "descend", "ascend"]}
            rowKey={(record) => record.id}
            className="notranslate"
            dataSource={bookState.books}
            size="small"
            pagination={{ showSizeChanger: true, defaultPageSize: 30 }}
          />

          <div className="loadingView" style={{ display: "none" }}>
            <LoadingIndicator />
          </div>

          {!bookState.isLoading && bookState.books.length === 0 ? (
            <div className="no-books-found">
              <span>No Books Found.</span>
            </div>
          ) : null}
        </div>
      </div>
    );
  } else return <div>Not login</div>;
}

export default withRouter(BookList);
